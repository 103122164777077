import { mapGetters, mapActions } from 'vuex';

export default {
	computed: {
		...mapGetters({
			isAlumni: 'profile/isAlumni',
		}),
		isBlockShown() {
			if (this.block.is_alumni_block === 0) {
				return true;
			} if (this.block.is_alumni_block === 1) {
				return this.isAlumni;
			} if (this.block.is_alumni_block === 2) {
				return !this.isAlumni;
			} if (this.block.is_alumni_block === 3) {
				return this.block.alumni_and_loyalty_check;
			}
			return true;
		},
	},
	created() {
		this.loadRoles();
	},
	methods: {
		...mapActions({
			loadRoles: 'profile/getRoles',
		}),
	},
};
