<template>
	<div
		v-if="isBlockShown"
		id="programm_announces"
		:class="'panel programms ' +
			(block.hide_top_indent ? ' mt-0 pt-0 ' : '') +
			(block.hide_bottom_indent ? ' mb-0 pb-0 ' : '') +
			block.appearance"
	>
		<div>
			<div class="container">
				<div class="panel_header">
					<div class="row align-items-end">
						<div class="col-12 col-lg-6">
							<h2>{{ block.block_title }}</h2>
						</div>
					</div>
				</div>
				<ul class="announce_list">
					<li
						v-for="item in content"
						v-if="item.fix_at_top"
						:key="item.id"
						class="row main_event"
						:style="'background-image:url(' + item.top_cover_image_url_wide + ')'"
					>
						<div class="col-12 col-md-4 col-lg-2">
							<div class="announce_date_block">
								<div class="row">
									<div class="col col-md-12">
										<div class="announce_date_day">
											<span>{{ item.event_date | moment("DD") }}</span>
											<a
												:href="'//sk.skolkovo.ru/api/calendar/event/' + item.id"
												class="add_announce"
											/>
										</div>
									</div>
									<div class="col col-md-12">
										<div class="announce_date_properties">
											<div class="announce_date_month">
												{{ item.event_date | moment("MMMM") }}
											</div>
											<div class="announce_date_time">
												{{ item.event_date | moment("HH:mm") }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-8 col-lg-6">
							<div class="announce_content_block">
								<div class="announce_content_header">
									<div class="tag_string">
										{{ item.type_titles.title_default }}

										<span
											v-if="item.taxonomy.project"
										> | {{ item.taxonomy.project.title }}</span>

										<span
											v-if="item.taxonomy.product"
										> | {{ item.taxonomy.product.title }}</span>
									</div>
								</div>
								<div class="announce_content_middle">
									<div
										v-if="block.show_alumni_link && !isAlumni"
										class="title"
									>
										{{ item.title }}
									</div>
									<nuxt-link
										v-else
										:to="$url.event(item.slug, item.typology_slug)"
										class="title"
									>
										{{ item.title }}
									</nuxt-link>
								</div>
								<div class="announce_content_footer">
									<div
										v-if="block.show_alumni_link && !isAlumni"
									>
									</div>

									<nuxt-link
										v-else
										:to="$url.event(item.slug, item.typology_slug)"
										class="more_link"
									>
										{{ $t('more') }}
									</nuxt-link>
								</div>
							</div>
						</div>
					</li>
					<li
						v-for="item in content"
						v-if="!item.fix_at_top"
						:key="item.id"
						class="row"
					>
						<div class="col-12 col-sm-4 col-lg-2">
							<div class="announce_date_block">
								<div class="row">
									<div class="col col-sm-12">
										<div class="announce_date_day">
											<span>{{ item.event_date | moment("DD") }}</span>
											<a
												:href="'//sk.skolkovo.ru/api/calendar/event/' + item.id"
												class="add_announce"
												aria-label="Добавить в календарь"
											/>
										</div>
									</div>
									<div class="col col-sm-12">
										<div class="announce_date_properties">
											<div class="announce_date_month">
												{{ item.event_date | moment("MMMM") }}
											</div>
											<div class="announce_date_time">
												{{ item.event_date | moment("HH:mm") }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-sm-8 col-lg-4">
							<div class="announce_image_block">
								<div
									v-if="block.show_alumni_link && !isAlumni"
								>
									<b-img-lazy
										:src="item.top_cover_image_url_small"
										:alt="item.title"
									/>
								</div>

								<nuxt-link
									v-else
									:to="$url.event(item.slug, item.typology_slug)"
									class="title"
								>
									<b-img-lazy
										:src="item.top_cover_image_url_small"
										:alt="item.title"
									/>
								</nuxt-link>
							</div>
						</div>
						<div class="col-12 col-lg-6">
							<div class="announce_content_block">
								<div class="announce_content_header">
									<ul class="tag_list">
										<li>
											{{ item.type_titles.title_default }}
										</li>
										<li v-if="item.taxonomy && item.taxonomy.project">
											{{ item.taxonomy.project.title }}
										</li>
										<li v-if="item.taxonomy && item.taxonomy.product">
											{{ item.taxonomy.product.title }}
										</li>
										<li v-for="section in item.taxonomy.sections" :key="section.title">
											{{ section.title }}
										</li>
									</ul>
								</div>

								<div class="announce_content_middle">
									<div
										v-if="block.show_alumni_link && !isAlumni"
										class="title"
									>
										{{ item.title }}
									</div>
									<nuxt-link
										v-else
										:to="$url.event(item.slug, item.typology_slug)"
										class="title"
									>
										{{ item.title }}
									</nuxt-link>

									<p class="description">
										{{ item.subtitle }}
									</p>
								</div>
								<div class="announce_content_footer">
									<div
										v-if="block.show_alumni_link && !isAlumni"
									>
									</div>

									<nuxt-link
										v-else
										:to="$url.event(item.slug, item.typology_slug)"
										class="more_link"
									>
										{{ $t('more') }}
									</nuxt-link>
								</div>
							</div>
						</div>
					</li>
				</ul>
				<div class="panel_footer" v-if="isFooterVisible">
					<div class="row">
						<div class="col-12 col-md-4 col-lg-2">
							<!-- <nuxt-link to="/navigator/events/" class="btn btn-primary mb-4">
								{{ $t('moreEvents') }}
							</nuxt-link> -->
						</div>
						<div class="col-12 col-md-8 col-lg-6 offset-lg-4">
							<div class="panel_footer_info">
								<div class="title">
									{{ $t('suitableEvent') }}
								</div>
								<p
									class="description"
								>
									{{ $t('fullInfo') }}
								</p>
								<div class="btn-panel">
									<a
										id="announces_jivosite_top"
										onclick="jivo_api.open(); return false;"
										href="javascript:void(0)"
										class="btn btn-secondary-outer"
										role="button"
									>{{ $t('requestСonsultation') }}</a>
									<!--<a
										href="javascript:void(0)"
										onclick="ComagicWidget.openSitePhonePanel(); return false;"
										class="btn btn-secondary-outer"
									>Обратный звонок</a>-->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { directive } from 'vue-awesome-swiper';
import '@/utils/moment';
import { mapActions } from 'vuex';
import checkIsBlockShown from '~/mixins/checkIsBlockShown';

export default {
	name: 'PanelDynamicEventsAnnounces',
	directives: {
		swiper: directive,
	},
	props: {
		block: {
			type: Object,
			default: null,
		},
	},
	mixins: [checkIsBlockShown],
	data() {
		return {
			fetchedData: [],
			activeClass: 'active',
			announceActiveButtons: {
				'': true,
			},
			announceButtons: [
				['', 'Все'],
				['0', 'Вебинары'],
				['2', 'Online-конференции'],
				['18', 'Live курс'],
				// ["4", "Очные мероприятия"],
				// ["5", "Онлайн-дискуссии"]
			],
			options: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				// freeMode: true,
				loop: false,
			},
		};
	},
	computed: {
		content() {
			return this.block.events;
		},
	},
	created() {
		// this.checkValue();
		// if (this.$store.state.alumni.panelDynamicEventsAnn && !this.$auth.loggedIn) {
		// 	this.showLoginPopup();
		// }
	},
	mounted() {
		this.$pagesApi.events({ per_page: 5, index_list: true })
			.then((response) => { this.fetchedData = response; });
	},
	methods: {
		loadAnnounces(type) {
			this.announceActiveButtons = {};
			this.announceActiveButtons[type] = true;
			this.$pagesApi.events({ per_page: 5, index_list: true, type })
				.then((response) => { this.fetchedData = response; });
		},
	},
	...mapActions({
		showLoginPopup: 'authModal/showLogin',
	}),
	// ...mapMutations({
	// 	checkAlumni: 'alumni/panelDynamicEventsAnn',
	// }),
	// checkValue() {
	// 	if (this.block.is_alumni === true) {
	// 		this.checkAlumni(true);
	// 	}
	// },
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-announces";
</style>
